import React, { Component } from "react";
import "./index.css";
import { Col, Form, Card } from "react-bootstrap";
import {
  phonePattern,
  relationship,
} from "../../CreditApplicationForm/constants";

class ApplicantIsNotADriver extends Component {
  render() {
    var states = [
      "AL",
      "AK",
      "AS",
      "AZ",
      "AR",
      "CA",
      "CO",
      "CT",
      "DE",
      "DC",
      "FM",
      "FL",
      "GA",
      "GU",
      "HI",
      "ID",
      "IL",
      "IN",
      "IA",
      "KS",
      "KY",
      "LA",
      "ME",
      "MH",
      "MD",
      "MA",
      "MI",
      "MN",
      "MS",
      "MO",
      "MT",
      "NE",
      "NV",
      "NH",
      "NJ",
      "NM",
      "NY",
      "NC",
      "ND",
      "MP",
      "OH",
      "OK",
      "OR",
      "PW",
      "PA",
      "PR",
      "RI",
      "SC",
      "SD",
      "TN",
      "TX",
      "UT",
      "VT",
      "VI",
      "VA",
      "WA",
      "WV",
      "WI",
      "WY",
    ];
    return (
      <React.Fragment>
        <Card className="mt-4" bg="light">
          <Card.Header className="h5 font-weight-bold text-center">
            Driver information, if different from applicant.
          </Card.Header>
          <Card.Body>
            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Driver Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  name="driver_company_name"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  Driver First Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="20"
                  name="driver_first_name"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  Driver Middle Initial
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="1"
                  name="driver_middle_initial"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  Driver Last Name
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="30"
                  name="driver_last_name"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Relationship to Applicant
                </Form.Label>
                <Form.Control
                  custom
                  as="select"
                  name="driver_relationship_to_applicant"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                >
                  <option value="">-</option>
                  {relationship.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="30"
                  name="driver_address"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">City</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="30"
                  name="driver_city"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  State/Province
                </Form.Label>
                <Form.Control
                  required
                  custom
                  as="select"
                  name="driver_state"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                >
                  <option value="">--</option>
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  ZIP/Postal Code
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="7"
                  name="driver_zip"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Driver Primary Phone
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="driver_primary_phone"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Driver Secondary Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="driver_secondary_phone"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Driver Email Address
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  maxLength="50"
                  name="driver_email"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Tax Identity Number
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern="[\d]{9}"
                  minLength="9"
                  maxLength="9"
                  name="driver_tax_identity_number"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Driver SSN/SIN
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  pattern="[\d]{9}"
                  minLength="9"
                  maxLength="9"
                  name="driver_ssn_sin"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Driver CDL No.
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="20"
                  name="driver_cdl_no"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  Driver CDL Years
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="4"
                  name="driver_cdl_years"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Driver Date of Birth
                </Form.Label>
                <Form.Control
                  type="date"
                  name="driver_birthday"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Driver Marital Status
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="Married"
                  value="Married"
                  name="driver_marital_status"
                  id="driverMaritalStatus1"
                  onChange={this.props.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Separated"
                  value="Separated"
                  name="driver_marital_status"
                  id="driverMaritalStatus2"
                  onChange={this.props.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Single"
                  value="Single"
                  name="driver_marital_status"
                  id="driverMaritalStatus3"
                  onChange={this.props.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  No. of Dependents
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="driver_no_of_dependents"
                  value={this.props.value}
                  onChange={this.props.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </Card.Body>
        </Card>
      </React.Fragment>
    );
  }
}

export default ApplicantIsNotADriver;
