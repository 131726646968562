export const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const relationship = [
  "Accountant",
  "Aunt",
  "Brother",
  "Brother-in-law",
  "Co-worker",
  "Cousin",
  "Daughter",
  "Daughter-in-law",
  "Employee",
  "Employer",
  "Father",
  "Father-in-law",
  "Friend",
  "Grandmother",
  "Grandfather",
  "Husband",
  "Mother",
  "Mother-in-law",
  "Neighbor",
  "Nephew",
  "Niece",
  "Partner",
  "Sister",
  "Sister-in-law",
  "Son",
  "Son-in-law",
  "Uncle",
  "Wife",
];

export const phonePattern = "[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}";
