import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./index.css";
import TrucksFindForm from "../../components/ui/TrucksFindForm";
import ImageSliderHome from "../../components/ui/ImageSliderHome";
import ThreeTrucks from "../../components/ui/ThreeTrucks";

export default function Home() {
  return (
    <Container fluid className="min-vh-100">
      <Container>
        <ImageSliderHome />
        <Row className="mt-4">
          <Col md={6} className="order-md-2">
            <TrucksFindForm />
          </Col>
          <Col md={6} className="order-md-1">
            <hr className="d-block d-md-none" />
            <h2 className="text-center text-md-left">Financing</h2>
            <p>
              We make it easy and affordable for you to finance your next truck.
            </p>
            <Link to="/credit-application">
              <Button className="mb-3 w-50" variant="dark">
                Apply now
              </Button>
            </Link>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13783.466371145016!2d-81.6197343!3d30.2693821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9632079d4793c147!2sAbsolute%20Motors!5e0!3m2!1sen!2s!4v1611140284605!5m2!1sen!2s"
              width="100%"
              height="289"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="map"
            ></iframe>
          </Col>
        </Row>
        <hr className="d-block d-md-none" />
        <Row className="mt-4">
          <h2 className="container text-center">Featured Trucks</h2>
          <ThreeTrucks />
        </Row>
      </Container>
    </Container>
  );
}
