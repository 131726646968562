import React from "react";
import { Row, Col } from "react-bootstrap";
import "./index.css";

export default function Contacts() {
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col sm={4} md={12} lg={4}>
          <span className="h5">Business Hours:</span>
        </Col>
        <Col sm={8} md={12} lg={8}>
          <Row>
            <Col sm={6} md={12} lg={6}>
              <span>Monday - Friday</span>
            </Col>
            <Col sm={6} md={12} lg={6}>
              <span className="d-block font-weight-bold mb-1">
                7:30AM - 5PM
              </span>
            </Col>
          </Row>
          <Row>
            <Col sm={6} md={12} lg={6}>
              <span>Saturday - Sunday</span>
            </Col>
            <Col>
              <span className="d-block font-weight-bold ">Closed</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4} md={12} lg={4}>
          <span className="h5">Address:</span>
        </Col>
        <Col sm={8} md={12} lg={8}>
          <a href="https://g.page/absolute-motors-jacksonville">
            5323 Philips Hwy, Jacksonville, FL, USA
          </a>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={4} md={12} lg={4} className="h5">
          Phone:
        </Col>
        <Col sm={8} md={12} lg={8}>
          <a href="tel:+1 (904) 524-6482" className="d-block h5">
            +1 (904) 524-6482
          </a>
          <a href="tel:+1 (904) 334-7470" className="d-block">
            +1 (904) 334-7470
          </a>
        </Col>
      </Row>
      <Row>
        <Col sm={4} md={12} lg={4} className="h5">
          Email:
        </Col>
        <Col sm={8} md={12} lg={8}>
          <a href="mailto: absolutemotors2016@gmail.com">
            absolutemotors2016@gmail.com
          </a>
        </Col>
      </Row>
    </React.Fragment>
  );
}
