import React, { Component } from "react";
import "./index.css";
import { Col, Form } from "react-bootstrap";
import { phonePattern } from "../../CreditApplicationForm/constants";

class RentHome extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label className="font-weight-bold">Landlord Name</Form.Label>
            <Form.Control
              type="text"
              name="rent_home_landlord_name"
              maxLength="75"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={4}>
            <Form.Label className="font-weight-bold">
              Landlord Phone Number
            </Form.Label>
            <Form.Control
              type="text"
              pattern={phonePattern}
              name="rent_home_landlord_phone"
              minLength="10"
              maxLength="12"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>
        </Form.Row>
      </React.Fragment>
    );
  }
}

export default RentHome;
