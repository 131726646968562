import React from "react";
import { Container } from "react-bootstrap";
import "./index.css";

export default function Page404() {
  return (
    <Container fluid className="min-vh-100">
      <Container>
        <h2 className="text-center">404</h2>
        <h4 className="text-center">Page not found</h4>
      </Container>
    </Container>
  );
}
