import React, { Component } from "react";
import "./index.css";
import { withRouter } from "react-router-dom";
import { Col, Form, Alert, Button, Card, Accordion } from "react-bootstrap";
import { states, relationship, phonePattern } from "./constants";
import RentHome from "../CreditApplicationFormComponents/RentHome";
import BusinessNonProprietorship from "../CreditApplicationFormComponents/BusinessNonProprietorship";
import WithSpouse from "../CreditApplicationFormComponents/WithSpouse";
import IsPreviousTruckOwner from "../CreditApplicationFormComponents/IsPreviousTruckOwner";
import ApplicantIsNotADriver from "../CreditApplicationFormComponents/ApplicantIsNotADriver";
import axios from "../../../axios";

class CreditApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // personal information
      company_name: null,
      first_name: null,
      middle_initial: null,
      last_name: null,
      suffix: null,
      address: null,
      city: null,
      country: null,
      state: null,
      zip: null,
      primary_phone: null,
      secondary_phone: null,
      email: null,
      tax_identity_number: null,
      ssn_sin: null,
      cdl_no: null,
      cdl_years: null,
      // birthday: undefined,
      marital_status: null,
      no_of_dependents: null,
      own_or_rent_home: null,
      rent_home_landlord_name: null,
      rent_home_landlord_phone: null,
      how_long_at_address_year: null,
      how_long_at_address_month: null,
      years_in_area: null,
      former_address: null,
      own_home_how_long_at_address_year: null,
      own_home_how_long_at_address_month: null,

      // business information
      business_type: null,
      business_name: null,
      business_tax_id_number: null,
      business_address: null,
      business_phone_number: null,
      bankruptcy: null,
      tax_liens: null,
      had_a_home_foreclosure: null,
      item_repossessed: null,
      business_explain: null,

      // list of references
      reference_1_first_last_name: null,
      reference_1_country: null,
      reference_1_state: null,
      reference_1_phone: null,
      reference_1_relationship: null,
      reference_2_first_last_name: null,
      reference_2_country: null,
      reference_2_state: null,
      reference_2_phone: null,
      reference_2_relationship: null,

      // spouse information
      with_spouse: null,
      spouse_first_name: null,
      spouse_middle_initial: null,
      spouse_last_name: null,
      spouse_birthday: null,
      spouse_ssn_sin: null,
      spouse_employer: null,
      spouse_position_held: null,
      spouse_position_long: null,
      spouse_phone_number: null,
      spouse_email: null,

      // applicant's employment history
      current_employer: null,
      current_employer_phone: null,
      current_employment_position: null,
      current_how_long_year: null,
      current_how_long_month: null,
      past_employer: null,
      past_employer_phone: null,
      past_employment_position: null,
      past_how_long_year: null,
      past_how_long_month: null,

      // truck ownership and usage
      ownership_company: null,
      ownership_city: null,
      ownership_state: null,
      ownership_contact: null,
      ownership_phone_number: null,
      is_previous_truck_owner: null,
      previous_truck_owner_current_nr: null,
      previous_truck_owner_previous_nr: null,

      // applicant is not the driver
      applicant_applicant_is_driver: null,
      driver_company_name: null,
      driver_first_name: null,
      driver_middle_initial: null,
      driver_last_name: null,
      driver_relationship_to_applicant: null,
      driver_address: null,
      driver_city: null,
      driver_state: null,
      driver_zip: null,
      driver_primary_phone: null,
      driver_secondary_phone: null,
      driver_email: null,
      driver_tax_identity_number: null,
      driver_ssn_sin: null,
      driver_cdl_no: null,
      driver_cdl_years: null,
      driver_birthday: null,
      driver_marital_status: null,
      driver_no_of_dependents: null,

      // balance sheet
      personal_vehicles_owned_1: null,
      balance_value_1: null,
      balance_lender_1: null,
      balance_balance_1: null,
      personal_vehicles_owned_2: null,
      balance_value_2: null,
      balance_lender_2: null,
      balance_balance_2: null,
      other_assets_1: null,
      other_assets_value_1: null,
      other_debts_1: null,
      other_debts_balance_1: null,
      other_assets_2: null,
      other_assets_value_2: null,
      other_debts_2: null,
      other_debts_balance_2: null,

      // bank account information
      bank_name: null,
      bank_city: null,
      bank_state: null,

      // digital signature
      signature: null,

      // credit application agreement
      is_agree: null,

      // status
      isSent: null,
      validationStarted: true,
      isValidated: null,
      errorStatus: null,
      isSubmited: null,
    };
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  handleChangeWithSpouce = () => {
    this.setState({
      with_spouse: !this.state.with_spouse,
      spouse_first_name: null,
      spouse_middle_initial: null,
      spouse_last_name: null,
      spouse_birthday: null,
      spouse_ssn_sin: null,
      spouse_employer: null,
      spouse_position_held: null,
      spouse_position_long: null,
      spouse_phone_number: null,
      spouse_email: null,
    });
  };

  handleChangeFile = (event) => {
    this.setState({ signature: event.target.files[0].name });
  };

  handleChangeIsAgree = () => {
    this.setState({ is_agree: !this.state.is_agree });
  };

  clearRentHome = () => {
    this.setState({
      rent_home_landlord_name: null,
      rent_home_landlord_phone: null,
    });
  };

  clearNotProprietorshipBusinessType = () => {
    this.setState({
      business_name: null,
      business_tax_id_number: null,
      business_address: null,
      business_phone_number: null,
    });
  };

  clearPreviousTruckOwner = () => {
    this.setState({
      previous_truck_owner_current_nr: null,
      previous_truck_owner_previous_nr: null,
    });
  };

  clearApplicantDriver = () => {
    this.setState({
      driver_first_name: null,
      driver_middle_initial: null,
      driver_last_name: null,
      driver_relationship_to_applicant: null,
      driver_address: null,
      driver_city: null,
      driver_state: null,
      driver_zip: null,
      driver_primary_phone: null,
      driver_secondary_phone: null,
      driver_email: null,
      driver_ssn_sin: null,
      driver_cdl_no: null,
      driver_cdl_years: null,
      driver_birthday: null,
      driver_marital_status: null,
      driver_no_of_dependents: null,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.stopPropagation();
      this.setState({ validationStarted: true, isValidated: false });
    }

    if (form.checkValidity() === true) {
      this.setState({ isValidated: true, isSubmited: true, errorStatus: null });
    }
  };

  async componentDidUpdate() {
    if (
      this.state.isValidated === true &&
      this.state.is_agree === true &&
      this.state.isSubmited === true
    ) {
      let isSentError = false;
      let formData = new FormData();

      for (let key in this.state) {
        const value = this.state[key];
        if (!value) continue;

        formData.append(key, value);
      }

      const data = document.getElementById("signatureFile");
      const file = data.files[0];
      formData.append("digital_signature", file);

      try {
        await axios.post("/api/credit-application/", formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        // const response = await fetch(
        //   `${process.env.REACT_APP_DB_HOST}/api/credit-application/`,
        //   {
        //     method: "POST",
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "multipart/form-data",
        //     },
        //     body: JSON.stringify(formData),
        //   }
        // );
        // if (response.status === 400) {
        //   var responseStatus = 400;
        //   throw response.statusText;
        // }
        // if (response.status === 500) {
        //   responseStatus = 500;
        //   throw response.statusText;
        // }
      } catch (error) {
        // console.log(error.response);
        // console.log(error.data);
        // console.log(error.message);
        // console.log(error);

        if (error.message === "Error: Request failed with status code 400") {
          var responseStatus = 400;
        }

        if (error.message === "Error: Request failed with status code 500") {
          responseStatus = 500;
        }

        isSentError = true;
      } finally {
        if (!isSentError) {
          this.setState({ isSent: true, isSubmited: false });
          window.scrollTo(0, 0);
        } else if (responseStatus === 400) {
          this.setState({ errorStatus: 400, isSubmited: false });
        } else if (responseStatus === 500) {
          this.setState({ errorStatus: 500, isSubmited: false });
        } else {
          this.setState({ isSubmited: false });
        }
      }
    }
  }

  render() {
    if (this.state.isSent === true) {
      return (
        <div className="vh-100 text-center h1">
          <Alert variant="success">Your request has been sent!</Alert>
        </div>
      );
    }

    if (this.state.own_or_rent_home === "Rent") {
      var rentHomeForm = <RentHome handleChange={this.handleChange} />;
    }

    if (
      this.state.business_type === "Partnership" ||
      this.state.business_type === "LLC" ||
      this.state.business_type === "Corporation"
    ) {
      var businessForm = (
        <BusinessNonProprietorship handleChange={this.handleChange} />
      );
    }

    if (
      this.state.bankruptcy === "Yes" ||
      this.state.tax_liens === "Yes" ||
      this.state.had_a_home_foreclosure === "Yes" ||
      this.state.item_repossessed === "Yes"
    ) {
      var isExplainBusiness = (
        <Form.Control
          required
          as="textarea"
          rows={3}
          maxLength="200"
          name="business_explain"
          value={this.state.value}
          onChange={this.handleChange}
        />
      );
    } else {
      isExplainBusiness = (
        <Form.Control
          as="textarea"
          rows={3}
          maxLength="200"
          name="business_explain"
          value={this.state.value}
          onChange={this.handleChange}
        />
      );
    }

    if (this.state.with_spouse === true) {
      var withSpouceForm = <WithSpouse handleChange={this.handleChange} />;
    }

    if (this.state.is_previous_truck_owner === "Yes") {
      var isPreviousTruckOwnerForm = (
        <IsPreviousTruckOwner handleChange={this.handleChange} />
      );
    }

    if (this.state.applicant_is_driver === "No") {
      var ApplicantIsNotADriverForm = (
        <ApplicantIsNotADriver handleChange={this.handleChange} />
      );
    }

    if (this.state.errorStatus === 400 || this.state.errorStatus === 500) {
      var isSentError = (
        <Form.Row>
          <Col md="12">
            <Alert variant="danger">
              An error has occurred. Please check your details and try again.
            </Alert>
          </Col>
        </Form.Row>
      );
    }

    if (this.state.isValidated === false) {
      var isNotValidated = (
        <Form.Row>
          <Col md="12">
            <Alert variant="danger">
              Please enter valid data to the required fields!
            </Alert>
          </Col>
        </Form.Row>
      );
    }

    return (
      <React.Fragment>
        <Form
          noValidate
          validated={this.state.validationStarted}
          onSubmit={this.handleSubmit}
        >
          <p>
            Please fill out this secure credit application. Once submitted, our
            representative will contact you.
          </p>

          <div id="personalInformation">
            <Alert variant="dark">PERSONAL INFORMATION</Alert>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Company Name
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  name="company_name"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="20"
                  name="first_name"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  Middle Initial
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="1"
                  name="middle_initial"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">Last Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="30"
                  name="last_name"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={1}>
                <Form.Label className="font-weight-bold">Suffix</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="4"
                  name="suffix"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">Address</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="30"
                  name="address"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">City</Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="30"
                  name="city"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">County</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="15"
                  name="country"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  State/Province
                </Form.Label>
                <Form.Control
                  required
                  custom
                  as="select"
                  name="state"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">--</option>
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  ZIP/Postal Code
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  maxLength="7"
                  name="zip"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Primary Phone
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="primary_phone"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Secondary Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="secondary_phone"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Email Address
                </Form.Label>
                <Form.Control
                  required
                  type="email"
                  maxLength="50"
                  name="email"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Tax Identity Number
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  pattern="[\d]{9}"
                  minLength="9"
                  maxLength="9"
                  name="tax_identity_number"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">SSN/SIN</Form.Label>
                <Form.Control
                  required
                  type="text"
                  pattern="[\d]{9}"
                  minLength="9"
                  maxLength="9"
                  name="ssn_sin"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">CDL No.</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="20"
                  name="cdl_no"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">CDL Years</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="4"
                  name="cdl_years"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Date of Birth
                </Form.Label>
                <Form.Control
                  type="date"
                  name="birthday"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Marital Status
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="Married"
                  value="Married"
                  id="maritalStatus1"
                  name="marital_status"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Separated"
                  value="Separated"
                  id="maritalStatus2"
                  name="marital_status"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Single"
                  value="Single"
                  id="maritalStatus3"
                  name="marital_status"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  No. of Dependents
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="no_of_dependents"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Own or Rent Home
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="Own"
                  value="Own"
                  name="own_or_rent_home"
                  id="ownOrRentHome1"
                  onChange={this.handleChange}
                  onClick={this.clearRentHome}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Rent"
                  value="Rent"
                  name="own_or_rent_home"
                  id="ownOrRentHome2"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            {rentHomeForm}

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  How Long at Address (Year/Month)
                </Form.Label>
                <br />
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="how_long_at_address_year"
                  value={this.value}
                  onChange={this.handleChange}
                />
                /
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="how_long_at_address_month"
                  value={this.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Years in Area
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="years_in_area"
                  value={this.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={8}>
                <Form.Label className="font-weight-bold">
                  Former Address, City, State, Zip (if less than 5 years at
                  current)
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="50"
                  name="former_address"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  How Long at Address (Year/Month)
                </Form.Label>
                <br />
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="own_home_how_long_at_address_year"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
                /
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="own_home_how_long_at_address_month"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </div>

          <div id="businessInformation">
            <Alert variant="dark mt-4">BUSINESS INFORMATION</Alert>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">
                  Business Type
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="Proprietorship"
                  value="Proprietorship"
                  name="business_type"
                  id="businessType1"
                  onChange={this.handleChange}
                  onClick={this.clearNotProprietorshipBusinessType}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Partnership"
                  value="Partnership"
                  name="business_type"
                  id="businessType2"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="LLC"
                  value="LLC"
                  name="business_type"
                  id="businessType3"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Corporation"
                  value="Corporation"
                  name="business_type"
                  id="businessType4"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            {businessForm}

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">
                  Have you ever taken bankruptcy?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  name="bankruptcy"
                  id="bankruptcy1"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Yes - Explain Below"
                  value="Yes"
                  name="bankruptcy"
                  id="bankruptcy2"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">
                  Are you subject to any tax liens?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  name="tax_liens"
                  id="taxLiens1"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Yes - Explain Below"
                  value="Yes"
                  name="tax_liens"
                  id="taxLiens2"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">
                  Have you ever had a home foreclosure or been evicted?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  name="had_a_home_foreclosure"
                  id="hadAHomeForeclosure1"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Yes - Explain Below"
                  value="Yes"
                  name="had_a_home_foreclosure"
                  id="hadAHomeForeclosure2"
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">
                  Have you ever had any item repossessed?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  name="item_repossessed"
                  id="itemRepossessed1"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="Yes - Explain Below"
                  value="Yes"
                  name="item_repossessed"
                  id="itemRepossessed2"
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="font-weight-bold">
                  Explain any 'Yes' answer (Less than 200 characters):
                </Form.Label>
                {isExplainBusiness}
              </Form.Group>
            </Form.Row>
          </div>

          <div id="listOfReferences">
            <Alert variant="dark mt-4">LIST OF REFERENCES</Alert>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  First & Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="reference_1_first_last_name"
                  maxLength="40"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  City/State(Province)
                </Form.Label>
                <br />
                <Form.Control
                  className="w-50 d-inline"
                  type="text"
                  name="reference_1_country"
                  maxLength="25"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
                /
                <Form.Control
                  className="w-40 d-inline align-bottom"
                  custom
                  as="select"
                  name="reference_1_state"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">--</option>
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Contact Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="reference_1_phone"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Relationship
                </Form.Label>
                <Form.Control
                  custom
                  as="select"
                  name="reference_1_relationship"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">-</option>
                  {relationship.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  First & Last Name
                </Form.Label>
                <Form.Control
                  type="text"
                  name="reference_2_first_last_name"
                  maxLength="40"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  City/State(Province)
                </Form.Label>
                <br />
                <Form.Control
                  className="w-50 d-inline"
                  type="text"
                  name="reference_2_country"
                  maxLength="25"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
                /
                <Form.Control
                  className="w-40 d-inline align-bottom"
                  custom
                  as="select"
                  name="reference_2_state"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">--</option>
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Contact Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="reference_2_phone"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Relationship
                </Form.Label>
                <Form.Control
                  custom
                  as="select"
                  name="reference_2_relationship"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">-</option>
                  {relationship.map((value, index) => (
                    <option value={value} key={index}>
                      {value}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </div>

          <div id="spouseInformation">
            <Alert variant="dark mt-4">SPOUSE INFORMATION</Alert>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Check
                  type="checkbox"
                  label="Check this box only if this is a joint application with your spouse, or if you are relying on your spouse's income or assets as a basis for repayment of the credit requested, or if you reside in a community property state."
                  name="with_spouse"
                  onChange={this.handleChangeWithSpouce}
                />
              </Form.Group>
            </Form.Row>

            {withSpouceForm}
          </div>

          <div id="applicantsEmploymentHistory">
            <Alert variant="dark mt-4">APPLICANT'S EMPLOYMENT HISTORY</Alert>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Current Employer
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="current_employer"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Employer Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="current_employer_phone"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">Position</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="current_employment_position"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  How Long (Year/Month)
                </Form.Label>
                <br />
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="current_how_long_year"
                  value={this.value}
                  onChange={this.handleChange}
                />
                /
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="current_how_long_month"
                  value={this.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Immediate Past Employer
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="past_employer"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  Employer Phone
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="past_employer_phone"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">Position</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="past_employment_position"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  How Long (Year/Month)
                </Form.Label>
                <br />
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="past_how_long_year"
                  value={this.value}
                  onChange={this.handleChange}
                />
                /
                <Form.Control
                  className="w-40 d-inline"
                  type="text"
                  pattern="\d*"
                  maxLength="2"
                  name="past_how_long_month"
                  value={this.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </div>

          <div id="truckOwnershipAndUsage">
            <Alert variant="dark mt-4">TRUCK OWNERSHIP AND USAGE</Alert>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="font-weight-bold">
                  Primary Haul Source (Do not list own company)
                </Form.Label>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">Company</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="ownership_company"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">City</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="ownership_city"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={3}>
                <Form.Label className="font-weight-bold">
                  State/Province
                </Form.Label>
                <Form.Control
                  custom
                  as="select"
                  name="ownership_state"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">--</option>
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">Contact</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="ownership_contact"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={6}>
                <Form.Label className="font-weight-bold">
                  Phone Number
                </Form.Label>
                <Form.Control
                  type="text"
                  pattern={phonePattern}
                  minLength="10"
                  maxLength="12"
                  name="ownership_phone_number"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="font-weight-bold">
                  Previous Truck/Trailer Owner?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value="Yes"
                  name="is_previous_truck_owner"
                  id="isPreviousTruckOwner1"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  name="is_previous_truck_owner"
                  id="isPreviousTruckOwner2"
                  onChange={this.handleChange}
                  onClick={this.clearPreviousTruckOwner}
                />
              </Form.Group>
            </Form.Row>

            {isPreviousTruckOwnerForm}

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label className="font-weight-bold">
                  Is the applicant the driver?
                </Form.Label>
                <br />
                <Form.Check
                  inline
                  type="radio"
                  label="Yes"
                  value="Yes"
                  name="applicant_is_driver"
                  id="isDriver1"
                  onChange={this.handleChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  label="No"
                  value="No"
                  name="applicant_is_driver"
                  id="isDriver2"
                  onChange={this.handleChange}
                  onClick={this.clearApplicantDriver}
                />
              </Form.Group>
            </Form.Row>

            {ApplicantIsNotADriverForm}
          </div>

          <div id="balanceSheet">
            <Alert variant="dark mt-4">BALANCE SHEET</Alert>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Personal Vehicles Owned 1
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="personal_vehicles_owned_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Value $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="balance_value_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Name of Lender
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="balance_lender_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Balance $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="balance_balance_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Personal Vehicles Owned 2
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="personal_vehicles_owned_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Value $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="balance_value_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Name of Lender
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="balance_lender_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Balance $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="balance_balance_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Other Assets 1 (Itemize)
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="other_assets_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Value $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="other_assets_value_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Other Debts (Itemize)
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="other_debts_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Balance $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="other_debts_balance_1"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Other Assets 2 (Itemize)
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="other_assets_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Value $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="other_assets_value_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={4}>
                <Form.Label className="font-weight-bold">
                  Other Debts (Itemize)
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="other_debts_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">Balance $</Form.Label>
                <Form.Control
                  type="text"
                  pattern="\d*"
                  maxLength="7"
                  name="other_debts_balance_2"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>
            </Form.Row>
          </div>

          <div id="bankAccountInformation">
            <Alert variant="dark mt-4">BANK ACCOUNT INFORMATION</Alert>

            <Form.Row>
              <Form.Group as={Col} md={5}>
                <Form.Label className="font-weight-bold">Bank Name</Form.Label>
                <Form.Control
                  type="text"
                  maxLength="25"
                  name="bank_name"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={5}>
                <Form.Label className="font-weight-bold">City</Form.Label>
                <Form.Control
                  type="text"
                  name="bank_city"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </Form.Group>

              <Form.Group as={Col} md={2}>
                <Form.Label className="font-weight-bold">
                  State/Province
                </Form.Label>
                <Form.Control
                  custom
                  as="select"
                  maxLength="25"
                  name="bank_state"
                  value={this.state.value}
                  onChange={this.handleChange}
                >
                  <option value="">--</option>
                  {states.map((state, index) => (
                    <option value={state} key={index}>
                      {state}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
          </div>

          <div id="digitalSignature">
            <Alert variant="dark mt-4">DIGITAL SIGNATURE</Alert>

            <Form.Row>
              <Form.Group as={Col} md={5}>
                <Form.File
                  custom
                  required
                  label={this.state.signature ?? "No file chosen"}
                  id="signatureFile"
                  type="file"
                  accept="image/*"
                  name="signature"
                  value={this.state.value}
                  onChange={this.handleChangeFile}
                />
              </Form.Group>
            </Form.Row>
          </div>

          <div id="creditApplicationAgreement">
            <Alert variant="dark mt-4">CREDIT APPLICATION AGREEMENT</Alert>

            <Form.Row>
              <Form.Group as={Col}>
                <Form.Check
                  required
                  type="checkbox"
                  label="I have read and agree to the Credit Application Agreement."
                  name="is_agree"
                  onChange={this.handleChangeIsAgree}
                />
                <Accordion defaultActiveKey="0">
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    className="agreementBtn"
                  >
                    (Click to view/hide the agreement)
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card bg="light">
                      <Card.Body>
                        <p>
                          In order to induce Absolute Motors to extend credit,
                          applicant represents and warrants that the information
                          contained in the above credit application as well as
                          in any other document provided to Absolute Motors by
                          me or on my behalf now or at any time in the future,
                          is a truthful and accurate representation of my
                          financial condition. I further agree to notify
                          Absolute Motors in writing in the event of a material
                          change in my financial condition during any time
                          period I owe any monetary obligation to Absolute
                          Motors.
                        </p>
                        <p>
                          I further agree that Absolute Motors can use this
                          application as authorization to obtain any
                          documents/information needed by Absolute Motors from
                          third parties or entities in order to process my
                          request for the initial extension of credit by
                          Absolute Motors as well as during any time period in
                          which I owe any monetary obligation to Absolute
                          Motors.
                        </p>
                        <p>
                          I CONSENT TO ABSOLUTE MOTORS AND/OR ITS EMPLOYEES AND
                          AGENTS, RECORDING ANY INCOMING OR OUTGOING CALLS
                          BETWEEN ABSOLUTE MOTORS AND ME OR MY AGENTS AND
                          REPRESENTAVIVES.
                        </p>
                      </Card.Body>
                    </Card>
                  </Accordion.Collapse>
                </Accordion>
              </Form.Group>
            </Form.Row>
          </div>

          {isNotValidated}

          {isSentError}

          <Form.Row>
            <Col md="auto">
              <Button type="submit" className="mb-2 w-100" variant="dark">
                Submit
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default withRouter(CreditApplicationForm);
