import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_DB_HOST,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

Axios.interceptors.response.use(
  (res) => res,
  async (err) => {
    console.log(err);

    // await axio.post('error', {data: error})

    throw new Error(err);
  }
);
export default Axios;
