import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import "./index.css";
import { Container, Row, Form } from "react-bootstrap";
// import Pagination from "react-bootstrap-4-pagination";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import NoResults from "../NoResults";
import TruckCard from "../TruckCard";
import InfiniteScroll from "react-infinite-scroll-component";

class TrucksFindResults extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trucks: null,
      page: 1,
      isLoading: true,
      orderBy: "-id",
    };
  }

  async componentDidMount() {
    const params = queryString.parse(this.props.location.search);
    if (Object.keys(params).length === 0) {
      var searchParams = "";
      // var pageParams = 1;
    } else {
      searchParams = `year_min=${params.fromYear}&year_max=${params.toYear}&mileage_min=${params.fromMileage}&mileage_max=${params.toMileage}&price_min=${params.fromPrice}&price_max=${params.toPrice}&search=${params.make}`;
      // if (params.page) {
      //   pageParams = params.page;
      // } else {
      //   pageParams = 1;
      // }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DB_HOST}/api/trucks/?${searchParams}&ordering=${this.state.orderBy}&page=${this.state.page}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      this.setState({
        trucks: data.results,
        nrOfTrucks: data.count,
        isNextPageExists: data.next,
        searchParams: searchParams,
        // pageParams: pageParams,
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevState.orderBy !== this.state.orderBy ||
      prevState.page !== this.state.page
    ) {
      const params = queryString.parse(this.props.location.search);
      if (Object.keys(params).length === 0) {
        var searchParams = "";
        // var pageParams = 1;
      } else {
        searchParams = `year_min=${params.fromYear}&year_max=${params.toYear}&mileage_min=${params.fromMileage}&mileage_max=${params.toMileage}&price_min=${params.fromPrice}&price_max=${params.toPrice}&search=${params.make}`;
        // if (params.page) {
        //   pageParams = params.page;
        // } else {
        //   pageParams = 1;
        // }
      }

      if (prevState.orderBy !== this.state.orderBy) {
        this.setState({ isLoadingPage: true, page: 1 });
        var trucks = [];
      } else {
        this.setState({ isLoadingPage: true });
        trucks = this.state.trucks;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_DB_HOST}/api/trucks/?${searchParams}&ordering=${this.state.orderBy}&page=${this.state.page}`,
          {
            method: "GET",
          }
        );
        const data = await response.json();
        this.setState({
          trucks: [...trucks, ...data.results],
          nrOfTrucks: data.count,
          isNextPageExists: data.next,
          searchParams: searchParams,
          // pageParams: pageParams,
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.setState({ isLoadingPage: false });
      }
    }
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value, trucks: [] });
  };

  nextPage = () => {
    if (this.state.isNextPageExists !== null) {
      // this.setState((prevState) => ({ page: prevState.page + 1 }));
      this.setState({ page: this.state.page + 1 });
    }
  };

  render() {
    if (this.state.isLoading)
      return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      );
    else if (!this.state.trucks)
      return (
        <div className="mt-4">
          <LoadingError />
        </div>
      );

    if (this.state.isLoadingPage)
      var loadingPage = (
        <div className="d-flex align-items-center justify-content-center mb-3">
          <Loading />
        </div>
      );

    if (this.state.trucks.length === 0 && !this.state.isLoadingPage) {
      return <NoResults className="mt-5" />;
    }

    // let paginationConfig = {
    //   totalPages: 22,
    //   currentPage: 15,
    //   showMax: 5,
    //   prevNext: true,
    //   href: `${process.env.REACT_APP_WEB_HOST}/trucks/?${this.state.searchParams}&ordering=${this.state.orderBy}&page=*`, // * will be replaced by the page number
    //   pageOneHref: `${process.env.REACT_APP_WEB_HOST}/trucks`,
    // };

    return (
      <Container>
        {/* <Pagination {...paginationConfig} /> */}

        <Form>
          <Form.Group controlId="OrderBy">
            <Form.Label>Order by</Form.Label>
            <Form.Control
              custom
              as="select"
              name="orderBy"
              defaultValue={this.state.orderBy}
              value={this.state.value}
              onChange={this.handleChange}
            >
              <option value="-id">New in stock</option>
              <option value="id">Old in stock</option>
              <option value="car_manufacturer">Make (Ascending)</option>
              <option value="-car_manufacturer">Make (Descending)</option>
              <option value="year">Year (Ascending)</option>
              <option value="-year">Year (Descending)</option>
              <option value="mileage">Mileage (Ascending)</option>
              <option value="-mileage">Mileage (Descending)</option>
              <option value="price">Price (Ascending)</option>
              <option value="-price">Price (Descending)</option>
            </Form.Control>
          </Form.Group>
        </Form>
        <Row>
          <InfiniteScroll
            // dataLength={this.state.nrOfTrucks}
            dataLength={this.state.trucks.length}
            next={this.nextPage}
            hasMore={true}
            className="d-flex flex-wrap"
          >
            <TruckCard trucks={this.state.trucks} />
          </InfiniteScroll>
        </Row>
        {loadingPage}
      </Container>
    );
  }
}

export default withRouter(TrucksFindResults);
