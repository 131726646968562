import React from "react";
import "./index.css";
import { Carousel } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function ImageSlider(props) {
  return (
    <Carousel>
      {props.images.map((image, index) => {
        return (
          <Carousel.Item key={index} className="truck-image">
            <LazyLoadImage
              className="d-block w-100"
              // src={`${process.env.REACT_APP_DB_HOST}${image.image}`} // for local db
              src={image.image}
              alt={index}
            />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
