import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import { Facebook, Youtube } from "react-bootstrap-icons";
import Contacts from "../Contacts";

export default function Footer() {
  return (
    <footer className="bg-dark text-white mt-2">
      <Container className="py-3">
        <Row>
          <Col md={6} className="mb-3 mb-md-0">
            <Contacts />
          </Col>
          <Col
            md={6}
            className="d-flex justify-content-center flex-row flex-md-column align-items-end"
          >
            <a
              href="https://www.facebook.com/serg.rusev.9"
              className="d-md-block m-2"
            >
              <Facebook size={35} color="#4267B2" />
            </a>

            <a
              href="https://www.youtube.com/channel/UC7fhQUzxp1ka2A7HT69ZY2Q"
              className="d-md-block m-2"
            >
              <Youtube size={35} color="red" />
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
