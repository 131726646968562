import React from "react";
import { Link } from "react-router-dom";
import { Container, Nav, Navbar, Row, Col } from "react-bootstrap";
import "./index.css";
import Brand from "../../../images/logo-header.png";
import { Facebook, Youtube } from "react-bootstrap-icons";

export default function Navigationbar() {
  return (
    <React.Fragment>
      <Navbar expand="lg" variant="light" bg="light">
        <Container>
          <Row className="flex-grow-1">
            <Col md={4} className="text-center text-md-left">
              <Navbar.Text>
                <a href="https://g.page/absolute-motors-jacksonville">
                  5323 Philips Hwy, Jacksonville, FL, USA
                </a>
              </Navbar.Text>
            </Col>
            <Col md={4} className="text-center">
              <Navbar.Text>
                <a href="tel:+1 (904) 524-6482" className="h5">
                  +1 (904) 524-6482
                </a>
              </Navbar.Text>
            </Col>
            <Col md={4} className="text-center text-md-right">
              <Navbar.Text>
                <a
                  href="https://www.facebook.com/serg.rusev.9"
                  className="mr-3"
                >
                  <Facebook size={25} color="#4267B2" />
                </a>
                <a href="https://www.youtube.com/channel/UC7fhQUzxp1ka2A7HT69ZY2Q">
                  <Youtube size={25} color="red" />
                </a>
              </Navbar.Text>
            </Col>
          </Row>
        </Container>
      </Navbar>

      <Navbar collapseOnSelect expand="lg" className="mb-2">
        <Container>
          <Navbar.Brand
            as={Link}
            to="/"
            eventKey="0"
            className="navbar-logo"
            style={{ backgroundImage: `url(${Brand})` }}
          ></Navbar.Brand>

          <Navbar.Toggle
            className="nav-button"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav activeKey={0} className="ml-auto">
              <Nav.Link as={Link} to="/inventory" eventKey="1">
                Inventory
              </Nav.Link>
              <Nav.Link as={Link} to="/credit-application" eventKey="2">
                Financing
              </Nav.Link>
              <Nav.Link as={Link} to="/about" eventKey="3">
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </React.Fragment>
  );
}
