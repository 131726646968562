import React, { Component } from "react";
import "./index.css";
import { Col, Form } from "react-bootstrap";
import { phonePattern } from "../../CreditApplicationForm/constants";

class BusinessNonProprietorship extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label className="font-weight-bold">Business Name</Form.Label>
            <Form.Control
              type="text"
              maxLength="35"
              name="business_name"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={4}>
            <Form.Label className="font-weight-bold">
              Tax I.D. Number
            </Form.Label>
            <Form.Control
              type="text"
              pattern="\d*"
              minLength="9"
              maxLength="9"
              name="business_tax_id_number"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={4}>
            <Form.Label className="font-weight-bold">
              Business Address (If different from above)
            </Form.Label>
            <Form.Control
              type="text"
              maxLength="50"
              name="business_address"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={4}>
            <Form.Label className="font-weight-bold">
              Business Phone Number
            </Form.Label>
            <Form.Control
              type="text"
              pattern={phonePattern}
              minLength="10"
              maxLength="12"
              name="business_phone_number"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>
        </Form.Row>
      </React.Fragment>
    );
  }
}

export default BusinessNonProprietorship;
