import React from "react";
import "./index.css";
import { Card, Button, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
// import NoImage from "../../../images/no-cover.png";

export default function TruckCard(props) {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    })
      .format(value)
      .replace(/\D00(?=\D*$)/, "");

  return (
    <React.Fragment>
      {props.trucks.map((truck) => (
        <Col
          className="col-12 col-md-4 my-4 d-flex align-items-stretch"
          key={truck.id}
        >
          <Card className="min-w-custom">
            <Link to={`/truck/${truck.id}`} className="card-image-container">
              <LazyLoadImage
                // variant="top"
                className="card-image-inventory"
                src={truck.cover}
                alt={truck.name + " img"}
                effect="blur"
                // placeholderSrc={NoImage}
                // onError={(e) => {
                //   e.target.onerror = null;
                //   console.log(e.target.src);
                // }}
              />
            </Link>
            <Card.Body className="d-flex flex-column">
              <Card.Title>{truck.name}</Card.Title>
              <Card.Text>
                {truck.description.length > 70
                  ? truck.description.slice(0, 70) + "..."
                  : truck.description}
              </Card.Text>
              <Card.Text>Mileage: {truck.mileage}</Card.Text>
              <Card.Text>Price: {numberFormat(truck.price)}</Card.Text>
              <Link to={`/truck/${truck.id}`} className="mt-auto">
                <Button variant="dark">Info</Button>
              </Link>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </React.Fragment>
  );
}
