import React, { Component } from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";
import "./index.css";
import ImageSlider from "../../components/ui/ImageSlider";
import Loading from "../../components/ui/Loading";
import LoadingError from "../../components/ui/LoadingError";
import Contacts from "../../components/ui/Contacts";

export default class TruckDetails extends Component {
  state = {
    truck: null,
    isLoading: true,
  };

  async componentDidMount() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DB_HOST}/api/trucks/${this.props.match.params.id}`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      this.setState({
        truck: data,
        allImages: [{ id: 0, image: data.cover }, ...data.images],
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className="vh-100 d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      );
    else if (!this.state.truck)
      return (
        <div className="vh-100">
          <LoadingError />
        </div>
      );

    if (this.state.truck.status === "Pending") {
      var isPendingAlert = (
        <Alert variant="danger" className="text-center w-100 m-md-0 mb-lg-3">
          <Alert.Heading className="mb-0 mb-md-1">Is Pending</Alert.Heading>
        </Alert>
      );
    }

    const numberFormat = (value) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      })
        .format(value)
        .replace(/\D00(?=\D*$)/, "");

    return (
      <Container fluid className="min-vh-100">
        <Container>
          <h1>{this.state.truck.name}</h1>
          <hr className="mb-4" />
          <Row>
            <Col className="col-12 col-lg-6">
              <ImageSlider images={this.state.allImages} />
            </Col>

            <Col className="col-12 col-lg-6">
              <Row>
                <Col md={6}>
                  <h3 className="mt-4 mt-lg-0 mb-4">
                    Price: {numberFormat(this.state.truck.price)}
                  </h3>
                </Col>
                <Col
                  md={6}
                  className="d-flex align-items-center justify-content-center"
                >
                  {isPendingAlert}
                </Col>
              </Row>
              <Card>
                <Card.Header className="h5 font-weight-bold">
                  Seller's Info
                </Card.Header>
                <Card.Body>
                  <Contacts />
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Card className="mt-4 mb-4">
            <Card.Header className="h5 font-weight-bold">
              Description
            </Card.Header>
            <Card.Body>
              {this.state.truck.description.split("\n").map((item, key) => {
                return (
                  <span className="d-block mb-3" key={key}>
                    {item}
                    <br />
                  </span>
                );
              })}
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header className="h5 font-weight-bold">
              Specifications
            </Card.Header>
            <Card.Body>
              <Row>
                <Col className="col-12 col-md-4">
                  <span className="d-block mb-3">
                    <b>Location: </b>
                    {this.state.truck.location}
                  </span>
                  <span className="d-block mb-3">
                    <b>Condition: </b>
                    {this.state.truck.condition}
                  </span>
                  <span className="d-block mb-3">
                    <b>Serial Number / VIN: </b>
                    {this.state.truck.serial_number}
                  </span>
                  <span className="d-block mb-3">
                    <b>Stock Number: </b>
                    {this.state.truck.stock_number}
                  </span>
                  <span className="d-block mb-3">
                    <b>Color: </b>
                    {this.state.truck.color}
                  </span>
                  <span className="d-block mb-3">
                    <b>Mileage: </b>
                    {this.state.truck.mileage}
                  </span>
                  <span className="d-block mb-3">
                    <b>Horsepowe: </b>
                    {this.state.truck.horsepower}
                  </span>
                  <span className="d-block mb-3">
                    <b>Engine Manufacture: </b>
                    {this.state.truck.engine_manufacturer}
                  </span>
                </Col>
                <Col className="col-12 col-md-4">
                  <span className="d-block mb-3">
                    <b>Engine Model: </b>
                    {this.state.truck.engine_model}
                  </span>
                  <span className="d-block mb-3">
                    <b>Engine Overhaul: </b>
                    {this.state.truck.engine_overhaul}
                  </span>
                  <span className="d-block mb-3">
                    <b>Fuel Type: </b>
                    {this.state.truck.fuel_type}
                  </span>
                  <span className="d-block mb-3">
                    <b>Fuel Capacity: </b>
                    {this.state.truck.fuel_capacity}
                  </span>
                  <span className="d-block mb-3">
                    <b>Transmission: </b>
                    {this.state.truck.transmission}
                  </span>
                  <span className="d-block mb-3">
                    <b>Number of Speeds: </b>
                    {this.state.truck.number_of_speeds}
                  </span>
                  <span className="d-block mb-3">
                    <b>Suspension: </b>
                    {this.state.truck.suspension}
                  </span>
                  <span className="d-block mb-3">
                    <b>Ratio: </b>
                    {this.state.truck.ratio}
                  </span>
                </Col>
                <Col className="col-12 col-md-4">
                  <span className="d-block mb-3">
                    <b>Number of Rear Axles: </b>
                    {this.state.truck.number_of_rear_axles}
                  </span>
                  <span className="d-block mb-3">
                    <b>Engine Brake Wheels: </b>
                    {this.state.truck.engine_brake}
                  </span>
                  <span className="d-block mb-3">
                    <b>Tires: </b>
                    {this.state.truck.tires}
                  </span>
                  <span className="d-block mb-3">
                    <b>Super Singles: </b>
                    {this.state.truck.super_singles}
                  </span>
                  <span className="d-block mb-3">
                    <b>Sleeper: </b>
                    {this.state.truck.sleeper}
                  </span>
                  <span className="d-block mb-3">
                    <b>Sleeper Size (in): </b>
                    {this.state.truck.sleeper_size}
                  </span>
                  <span className="d-block mb-3">
                    <b>Number of Beds: </b>
                    {this.state.truck.number_of_beds}
                  </span>
                  <span className="d-block mb-3">
                    <b>Differential Lock Drive: </b>
                    {this.state.truck.differential_lock_drive}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </Container>
    );
  }
}
