import React from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import TrucksFindResults from "../../components/ui/TrucksFindResults";

export default function Inventory() {
  return (
    <Container fluid className="min-vh-100">
      <Container>
        <h2 className="text-center">Inventory</h2>
      </Container>
      <TrucksFindResults />
    </Container>
  );
}
