import React from "react";
import "./index.css";
import { Container } from "react-bootstrap";

export default function LoadingError() {
  return (
    // <Container fluid className="min-vh-100">
    <Container fluid className="text-center">
      <Container>
        <h2 className="text-center">Error</h2>
        <h4 className="text-center">Something went wrong</h4>
      </Container>
    </Container>
  );
}
