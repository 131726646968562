import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navigationbar from "./components/ui/Navbar";
import Footer from "./components/ui/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import Inventory from "./pages/Inventory";
import Truck from "./pages/TruckDetails";
import CreditApplication from "./pages/CreditApplication";
import About from "./pages/About";
import Page404 from "./pages/Page404";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Navigationbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/inventory" component={Inventory} />
          <Route path="/truck/:id" component={Truck} />
          <Route path="/credit-application" component={CreditApplication} />
          <Route path="/about" component={About} />
          <Route path="/" component={Page404} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
