import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./index.css";
import Hero from "../../images/about-hero.jpg";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function About() {
  return (
    <Container fluid className="min-vh-100">
      <Container>
        <div className="wide-page-image mb-4">
          <LazyLoadImage src={Hero} effect="blur" className="w-100 " />
        </div>
        <div className="pb-3">
          <p className="h4">
            <b>About the company:</b>
          </p>
          <p>
            Absolute Motors Inc. was founded in 2010 and has grown to become the
            leading source of pre-owned medium and heavy duty trucks across the
            country. We strived to build a solid reputation in the trucking
            industry by providing high-quality, pre-owned vehicles to customers,
            who want to maximize the value of their purchase.
          </p>
          <p>
            Our valued customers have helped us to gain a superior reputation,
            by recognizing what we do and how we do business. Providing
            high-quality, pre-owned trucks is our core business.
          </p>
          <p>
            Providing personalized customer service, we stock a huge and diverse
            inventory in our primary location in Jacksonville, FL. We would be
            honored to become your one-stop shop for financing, protection plans
            and more.
          </p>
        </div>

        <Row className="justify-content-md-center mb-4">
          <Col className="mb-3 mb-md-0">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13783.466371145016!2d-81.6197343!3d30.2693821!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9632079d4793c147!2sAbsolute%20Motors!5e0!3m2!1sen!2s!4v1611140284605!5m2!1sen!2s"
              width="100%"
              height="333"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="map"
            ></iframe>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
