import React, { Component } from "react";
import "./index.css";
import { Carousel } from "react-bootstrap";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

class ImageSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: null,
      isLoading: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DB_HOST}/api/home-slider/`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      this.setState({ images: data.results });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className="wide-page-image d-flex align-items-center justify-content-center">
          <Loading />
        </div>
      );
    else if (!this.state.images)
      return (
        <div className="wide-page-image d-flex align-items-center justify-content-center">
          <LoadingError />
        </div>
      );

    return (
      <Carousel>
        {this.state.images.map((image, index) => {
          return (
            <Carousel.Item key={index} className="wide-page-image">
              <a href={image.link_to}>
                <LazyLoadImage
                  className="d-block w-100"
                  src={image.image}
                  alt={image.link_to}
                  effect="blur"
                />
              </a>
            </Carousel.Item>
          );
        })}
      </Carousel>
    );
  }
}

export default ImageSlider;
