import React, { Component } from "react";
import "./index.css";
import { Col, Form } from "react-bootstrap";

class IsPreviousTruckOwner extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Row>
          <Form.Group as={Col} md={2}>
            <Form.Label className="font-weight-bold">Current #</Form.Label>
            <Form.Control
              custom
              as="select"
              name="previous_truck_owner_current_nr"
              value={this.props.value}
              onChange={this.props.handleChange}
            >
              <option value="">-</option>
              {[...Array(21)].map((e, index) => (
                <option value={index} key={index}>
                  {index}
                </option>
              ))}
              <option value="20+">20+</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} md={2}>
            <Form.Label className="font-weight-bold">Previous #</Form.Label>
            <Form.Control
              custom
              as="select"
              name="previous_truck_owner_previous_nr"
              value={this.props.value}
              onChange={this.props.handleChange}
            >
              <option value="">-</option>
              {[...Array(21)].map((e, index) => (
                <option value={index} key={index}>
                  {index}
                </option>
              ))}
              <option value="20+">20+</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
      </React.Fragment>
    );
  }
}

export default IsPreviousTruckOwner;
