import React, { Component } from "react";
import "./index.css";
import { Col, Form } from "react-bootstrap";
import { phonePattern } from "../../CreditApplicationForm/constants";

class WithSpouse extends Component {
  render() {
    return (
      <React.Fragment>
        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="font-weight-bold">
              Spouse's Name (First, M.I., Last)
            </Form.Label>
            <br />
            <Form.Control
              required
              className="w-40 d-inline"
              type="text"
              maxLength="20"
              name="spouse_first_name"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
            <Form.Control
              className="w-10 d-inline"
              type="text"
              maxLength="1"
              name="spouse_middle_initial"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
            <Form.Control
              required
              className="w-40 d-inline"
              type="text"
              maxLength="30"
              name="spouse_last_name"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={3}>
            <Form.Label className="font-weight-bold">
              Spouse Date of Birth
            </Form.Label>
            <Form.Control
              type="date"
              name="spouse_birthday"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={3}>
            <Form.Label className="font-weight-bold">Spouse SSN/SIN</Form.Label>
            <Form.Control
              required
              type="text"
              pattern="[\d]{9}"
              minlength="9"
              maxlength="9"
              name="spouse_ssn_sin"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="font-weight-bold">
              Spouse's employer, city & state
            </Form.Label>
            <Form.Control
              type="text"
              maxLength="50"
              name="spouse_employer"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={3}>
            <Form.Label className="font-weight-bold">Position Held</Form.Label>
            <Form.Control
              type="text"
              maxLength="50"
              name="spouse_position_held"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={3}>
            <Form.Label className="font-weight-bold">How Long</Form.Label>
            <Form.Control
              type="text"
              pattern="\d*"
              maxLength="3"
              name="spouse_position_long"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} md={6}>
            <Form.Label className="font-weight-bold">
              Spouse Mobile Phone
            </Form.Label>
            <Form.Control
              required
              type="text"
              pattern={phonePattern}
              minLength="10"
              maxLength="12"
              name="spouse_phone_number"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>

          <Form.Group as={Col} md={6}>
            <Form.Label className="font-weight-bold">
              Spouse Email Address
            </Form.Label>
            <Form.Control
              required
              type="email"
              maxLength="50"
              name="spouse_email"
              value={this.props.value}
              onChange={this.props.handleChange}
            />
          </Form.Group>
        </Form.Row>
      </React.Fragment>
    );
  }
}

export default WithSpouse;
