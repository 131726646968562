import React from "react";
import "./index.css";
import { Spinner } from "react-bootstrap";

export default function Loading() {
  return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="secondary">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}
