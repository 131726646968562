import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./index.css";
import TruckCard from "../TruckCard";
import Loading from "../Loading";
import LoadingError from "../LoadingError";
import { Row } from "react-bootstrap";

class ThreeTrucks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newTruck: [],
      // cheapestTruck: [],
      // oldTruck: [],

      isLoading1: true,
      // isLoading2: true,
      // isLoading3: true,
    };
  }

  async componentDidMount() {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DB_HOST}/api/trucks/?ordering=-id`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      this.setState({
        newTruck: [
          ...this.state.newTruck,
          data.results[0],
          data.results[1],
          data.results[2],
        ],
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        isLoading1: false,
      });
    }

    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_DB_HOST}/api/trucks/?ordering=price`,
    //     {
    //       method: "GET",
    //     }
    //   );
    //   const data = await response.json();
    //   this.setState({
    //     cheapestTruck: [...this.state.cheapestTruck, data.results[0]],
    //   });
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   this.setState({
    //     isLoading2: false,
    //   });
    // }

    // try {
    //   const response = await fetch(
    //     `${process.env.REACT_APP_DB_HOST}/api/trucks/?ordering=id`,
    //     {
    //       method: "GET",
    //     }
    //   );
    //   const data = await response.json();
    //   this.setState({
    //     oldTruck: [...this.state.oldTruck, data.results[0]],
    //   });
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   this.setState({
    //     isLoading3: false,
    //   });
    // }
  }

  render() {
    if (
      this.state.isLoading1
      // this.state.isLoading2 ||
      // this.state.isLoading3
    ) {
      return (
        <Row className="w-100 my-4 justify-content-center">
          <Loading />
        </Row>
      );
    } else if (
      this.state.newTruck.length === 0
      // this.state.cheapestTruck.length === 0 ||
      // this.state.oldTruck.length === 0
    ) {
      return <LoadingError />;
    }

    const trucks = this.state.newTruck.filter((element) => {
      return element !== undefined;
    });

    return (
      <React.Fragment>
        <TruckCard trucks={trucks} />
        {/* <TruckCard trucks={this.state.cheapestTruck} />
        <TruckCard trucks={this.state.oldTruck} /> */}
      </React.Fragment>
    );
  }
}
export default withRouter(ThreeTrucks);
