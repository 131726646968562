import React, { Component } from "react";
import "./index.css";
import { withRouter } from "react-router-dom";
import { Col, Form, Button } from "react-bootstrap";
import Loading from "../Loading";
// import LoadingError from "../LoadingError";

class TrucksFindForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,

      make: "",
      fromYear: "",
      toYear: "",
      fromMileage: 0,
      toMileage: "",
      fromPrice: 0,
      toPrice: "",
    };
  }

  // modify on com componentDidUpdate
  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DB_HOST}/api/truck-manufacturers/`,
        {
          method: "GET",
        }
      );
      const data = await response.json();
      this.setState({ allTrucksMake: data.results });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
    // console.log(this.state);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.history.push(
      `/inventory/?make=${this.state.make}&fromYear=${this.state.fromYear}&toYear=${this.state.toYear}&fromMileage=${this.state.fromMileage}&toMileage=${this.state.toMileage}&fromPrice=${this.state.fromPrice}&toPrice=${this.state.toPrice}&page=1`
    );
  };

  yearRange(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  render() {
    if (this.state.isLoading)
      return (
        <div className="d-flex align-items-center justify-content-center mt-5">
          <Loading />
        </div>
      );
    else if (!this.state.allTrucksMake) return "";
    // else if (!this.state.allTrucksMake) return <LoadingError />;

    var currentDate = new Date();
    var currentYear = currentDate.getFullYear();
    var years = this.yearRange(1990, currentYear + 1);
    years.reverse();

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit}>
          <h2 className="text-center text-md-left">Find your truck</h2>
          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>Make</Form.Label>
              <Form.Control
                custom
                as="select"
                name="make"
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option value="">All</option>
                {this.state.allTrucksMake.map((value, index) => (
                  <option value={value.car_manufacturer} key={index}>
                    {value.car_manufacturer}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>From Year</Form.Label>
              <Form.Control
                custom
                as="select"
                name="fromYear"
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option>All</option>
                {years.map((year) => {
                  return <option key={year}>{year}</option>;
                })}
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>To Year</Form.Label>
              <Form.Control
                custom
                as="select"
                name="toYear"
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option>All</option>
                {years.map((year) => {
                  return <option key={year}>{year}</option>;
                })}
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>From Mileage</Form.Label>
              <Form.Control
                custom
                as="select"
                name="fromMileage"
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option value="0">0</option>
                <option value="100000">100,000</option>
                <option value="200000">200,000</option>
                <option value="300000">300,000</option>
                <option value="400000">400,000</option>
                <option value="500000">500,000</option>
                <option value="600000">600,000</option>
                <option value="700000">700,000</option>
                <option value="800000">800,000</option>
                <option value="900000">900,000</option>
                <option value="1000000">1,000,000</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>To Mileage</Form.Label>
              <Form.Control
                custom
                as="select"
                name="toMileage"
                defaultValue={"unlimited"}
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option value="100000">100,000</option>
                <option value="200000">200,000</option>
                <option value="300000">300,000</option>
                <option value="400000">400,000</option>
                <option value="500000">500,000</option>
                <option value="600000">600,000</option>
                <option value="700000">700,000</option>
                <option value="800000">800,000</option>
                <option value="900000">900,000</option>
                <option value="1000000">1,000,000</option>
                <option value="unlimited">Unlimited</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} md={6}>
              <Form.Label>From Price</Form.Label>
              <Form.Control
                custom
                as="select"
                name="fromPrice"
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option value="0">$0</option>
                <option value="10000">$10,000</option>
                <option value="20000">$20,000</option>
                <option value="30000">$30,000</option>
                <option value="40000">$40,000</option>
                <option value="50000">$50,000</option>
                <option value="60000">$60,000</option>
                <option value="70000">$70,000</option>
                <option value="80000">$80,000</option>
                <option value="90000">$90,000</option>
                <option value="100000">$100,000</option>
              </Form.Control>
            </Form.Group>

            <Form.Group as={Col} md={6}>
              <Form.Label>To Price</Form.Label>
              <Form.Control
                custom
                as="select"
                name="toPrice"
                defaultValue={"unlimited"}
                value={this.state.value}
                onChange={this.handleChange}
              >
                <option value="10000">$10,000</option>
                <option value="20000">$20,000</option>
                <option value="30000">$30,000</option>
                <option value="40000">$40,000</option>
                <option value="50000">$50,000</option>
                <option value="60000">$60,000</option>
                <option value="70000">$70,000</option>
                <option value="80000">$80,000</option>
                <option value="90000">$90,000</option>
                <option value="100000">$100,000</option>
                <option value="unlimited">Unlimited</option>
              </Form.Control>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col md={6}>
              <Button
                type="submit"
                // className="mb-2 form-control"
                block
                variant="dark"
              >
                Search
              </Button>
            </Col>
          </Form.Row>
        </Form>
      </React.Fragment>
    );
  }
}

export default withRouter(TrucksFindForm);
