import React from "react";
import { Container } from "react-bootstrap";
import "./index.css";
import CreditApplicationForm from "../../components/ui/CreditApplicationForm";

export default function CreditApplication() {
  return (
    <Container fluid className="min-vh-100">
      <Container>
        <h2 className="text-center mb-5">Credit Application</h2>
        <CreditApplicationForm />
      </Container>
    </Container>
  );
}
